
import {Component, Vue} from "vue-property-decorator";
import {Auth} from "aws-amplify";
import {Getter} from "vuex-class";

@Component
export default class HeaderView extends Vue {

  @Getter getUserEmail!: string

  async onLogoutClick() {
    try {
      await Auth.signOut();
      await this.$router.push({name: 'login'})
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error signing out: ', error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async onChangeLanguage(lang: any) {
    this.$root.$i18n.locale = lang
  }

}
