import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import cs from 'vuetify/src/locale/cs'
import uk from 'vuetify/src/locale/uk'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: {uk, cs},
        current: 'uk'
    }
});
