import {AuthOptions} from "@aws-amplify/auth/lib/types";
import {I18n} from "aws-amplify";

const config: {
    graphqlUrl: string
    region: string
    Auth: AuthOptions
    registrationUrl: string
} = {
    graphqlUrl: 'https://mkkk6hdxlzccbevit2vjgvwppu.appsync-api.eu-central-1.amazonaws.com/graphql',
    region: 'eu-central-1',

    Auth: {
        userPoolId: 'eu-central-1_XuvIDVZvq',
        userPoolWebClientId: 'bs9otn03ojdjdu17tigkojgvd',
        region: 'eu-central-1',
        mandatorySignIn: true,
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    },

    registrationUrl: 'https://b6zmtrn5j3ayvp6wdfndtz7po40vgxsz.lambda-url.eu-central-1.on.aws',
}

I18n.putVocabularies({
    en: {
        'Scan then enter verification code': 'Scan then enter verification code (You can use any TOTP application, for example Google Authenticator)'
    }
})
I18n.setLanguage('en')

export default config
