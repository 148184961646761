import Vue from 'vue'
import Vuex from 'vuex'
import { CognitoUserSession } from "amazon-cognito-identity-js";

Vue.use(Vuex)

interface RootState {
    userSession?: CognitoUserSession
}


export default new Vuex.Store<RootState>({
    state: {
        userSession: undefined
    },
    getters: {
        getUserEmail(state): string {
            return state.userSession?.getIdToken().decodePayload().email || ''
        },
        getUserGroups(state): string[] {
            return state.userSession?.getIdToken().decodePayload()['cognito:groups'] || []
        },
        isAdmin(state, getters): boolean {
            return getters.getUserGroups.includes('admin')
        },
        isWarehouse(state, getters): boolean {
            return getters.getUserGroups.includes('warehouse')
        },
        isYouth(state, getters): boolean {
            return getters.getUserGroups.includes('youth')
        },
        isSocial(state, getters): boolean {
            return getters.getUserGroups.includes('social')
        },
        isEducation(state, getters): boolean {
            return getters.getUserGroups.includes('education')
        },
        isApprover(state, getters): boolean {
            return getters.getUserGroups.includes('approver')
        },
        isMedic(state, getters): boolean {
            return getters.getUserGroups.includes('medic')
        },
        isReception(state, getters): boolean {
            return getters.getUserGroups.includes('reception')
        }
    },
    mutations: {
        setUserSession(state, userSession: CognitoUserSession) {
            state.userSession = userSession
        }
    },
    actions: {},
    modules: {}
})
