import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const approver = {
    cs: {
        search: "Registration Search",
        create: "Registration",
    }, uk: {
        search: "Пошук реєстрації",
        create: "Реєстрація",
    }
}
const department = {
    cs: {
        personSearch: 'Find person',
        familyNotFound: 'familyNotFound',
        familyData: 'familyData',
        familyRegistered: 'Registered',
        family: 'family',
        helpNotAsked: "Допомога не надавалась",
        helpProvided: "helpProvided",
        todayHelpProvided: "Допомога надана сьогодні",
        helpProvidedLog: "helpProvidedLog",
        visitLog: "visitLog",
    }, uk: {
        personSearch: 'Пошук людини',
        familyNotFound: 'Родину не знайдено',
        familyData: 'Дані родини',
        familyRegistered: 'Зареєстрована',
        family: 'Родина',
        helpNotAsked: "Допомога не надавалась",
        helpProvided: "Допомога надавалась",
        todayHelpProvided: "Допомога надана сьогодні",
        helpProvidedLog: "Журнал надання допомоги",
        visitLog: "Журнал відвідувань",
    }
}
const registration = {
    cs: {
        form: 'Registration form',
        personalData: 'Personal data',
        educationLevel: 'Education level',
        education: 'Education',
        dateOfBirth: 'dateOfBirth',
        disabilityGroup: "Disability Group",
        disability: "Disability",
        employmentInCz: "Employment in Cz",
        employment: "Employment",
        educationalInstitution: "Educational Institution",
        employedFromDate: "Employed From Date",
        employedFrom: "Employed From",
        refugeeVisa: "Refugee visa in CR",
        zip: "Zip code",
        cityOfLiveInLatin: "City of live (latin)",
        streetOfLiveInLatin: "Street of live (latin)",
        addSpouse: "Add spouse",
        addKid: "Add kid",
        kids: "Kids",
        addressCr: "Address in CR",
        addressUa: "Address in UA",
        acceptRules: "Agree to the rules of assistance provided by MRIYA UA",
        registrationSearch: "Registration Search",
        familyRegister: "Family Register",
        visaReceivedDate: "Visa received date",
        visaReceived: "Visa received",
        personalDataSuffix: ". family member",
        acceptRulesInfoTitle: 'Interní směrnice spolku MRIYA UA z.s. při poskytnutí humanitární pomoci',
        acceptRulesInfo: `
V první řadě je poskytnutá pomoc:
1. Osobě, která má ukrajinské občanství a trvale pobývala na území daného státu do 25.02.2022.
2. Osobě, která prohlašuje, že poprvé vstoupila na území ČR po 25.02.2022
3. Osobě, která je zraněna, a potřebuje urgentní lékařskou péči.
4. Osobě, která má prokazatelnou invaliditu (handicap), vážnou nemoc, jiné zdravotní znevýhodnění.
5. Osobě pečující o jiného člena rodiny, který má prokazatelnou invaliditu (handicap), vážnou nemoc, jiné zdravotní
znevýhodnění.
6. Osobě v důchodu.
7. Matkám s nezletilými dětmi nebo popřípadě jinému zákonnému zástupci nezletilých děti, přednostně samoživitelům.
8. Osobě, která pochází z regionu Ukrajiny, ve kterém aktuálně probíhají přímé válečné boje.
9. Osobě, která prohlašuje, že nevlastní žádný osobní majetek určení k bydlení jak na území České republiky, tak ani na území
jiného členského státu EU.
10. Osobě, která prohlašuje, že nemá na území České republiky žádné příbuzné a ani známe, které by ji mohli pomoci s ubytováním
a obstarání si základních životných potřeb.
11. Osobě, která prohlašuje, že nemá žádné finanční prostředky, aby si obstarala základní životní potřeby samostatně.
Ostatním osobám žádajícím o pomoc bude poskytnutá pomoc druhořadně, a to za podmínek existence volných kapacit a
humanitárních prostředků.
* Osoba žádající o pomoc je obeznámená a souhlasí s tím, že organizace MRIYA UA z.s. IČO: 143 39 242 je nezisková organizace a
veškera pomoc, kterou organizace poskytuje je dobrovolní.
* Osoba žádající o pomoc je obeznámená a souhlasí s tím, že organizace MRIYA UA z.s. IČO: 143 39 242 ji poskytne pomoc pouze
jedenkrát. Opakovaně poskytnutá pomoc může být udělená pouze osobám nacházejících se ve výjimečné situaci, a to pouze ze
souhlasu k tomu pověřených osob.`,
        GDPRInfoTitle: '',
        GDPRInfo: `
Prohlašují, že jsem se seznámil s informacemi o zpracování osobních údajů podle nařízení EP a Rady (EU) č.2016/679 ze 
dne 27.04.2016 (“GDPR”) na území České republiky a na základě toho souhlasím s tím, aby MRIYA UA z.s. IČO: 143 39 242 
čí jím určení zpracovatel zpracovávali a chránili, po dobu poskytování služeb humanitární podpory pro moji osobu a mnou 
uvedených nezletilých děti na nezbytnou dobu ve smyslu právních předpisu České republiky, mé osobní údaje (vč. 
nezletilých děti) v tomto rozsahu: Jméno a Příjmení, datum narození, adresa trvalého a přechodného pobytu, číslo a 
typ dokladu prokazujícího totožnost osoby, statní příslušnost, to vše za účelem naplnění GDPR a ke splnění povinnosti 
MRIYA UA z.s. z GDPR vyplývajících a dále souhlasím s tím, aby MRIYA UA z.s. IČO: 143 39 242 poskytla, v souvislosti s 
poskytování humanitární podpory, moje uvedené osobní údaje dalšímu subjektu podílejícímu se v jakémkoli rozsahu na 
uskutečňování služeb humanitární podpory pro moji osobu. Návštěvník CPP MRIYA (Centra pomoci a podpory 
MRIYA) anebo příjemce plnění včetně služby od spolku MRIYA UA z.s. dále souhlasí s pořízením a použitím včetně 
prezentace svojí fotografie nebo audio/video záznamu svojí osoby ze strany spolku MRIYA UA z.s., pro účely prezentace 
CPP, spolkové činnosti anebo dokladování (prokazování) či prezentace činnosti spolku MRIYA UA z.s. ve smyslu jeho 
stanov.

*Svým podpisem dávám souhlas s využitím výše uvedených osobních údajů.`,
        notAccompanied: 'Not accompanied',
        notAdult: 'Osoby mladší 14 let musí být registrovány jako deti',
        noValue: "-----------------",
    },
    uk: {
        form: 'Форма реєстрації',
        personalData: 'Особисті дані',
        educationLevel: 'Рівень освіти',
        education: 'Освіта',
        dateOfBirth: 'Дата народження',
        disabilityGroup: "Група інвалідності",
        disability: "Інвалідність",
        employmentInCz: "Робота в Чехії",
        employment: "Робота",
        educationalInstitution: "Навчальний заклад",
        employedFromDate: "Працевлаштування від якої дати",
        employedFrom: "Працевлаштований від",
        refugeeVisa: "Віза біженця в Чехії",
        zip: "Поштовий індекс",
        cityOfLiveInLatin: 'Місто проживання (латинкою)',
        streetOfLiveInLatin: 'Вулиця проживання (латинкою)',
        addSpouse: "Додати чоловіка/дружину",
        addKid: "Додати дитину",
        kids: "Діти",
        addressCr: "Адреса в Чехії",
        addressUa: "Адреса в Україні",
        acceptRules: "Погоджуюсь з 'Правилами' надання допомоги організації MRIYA UA",
        registrationSearch: "Пошук реєстрації",
        familyRegister: "Реєстрація родини",
        visaReceivedDate: "Дата отримання візи тимчасового захистру",
        visaReceived: "Дата отримання візи",
        personalDataSuffix: "-го дорослого",
        acceptRulesInfoTitle: 'Внутрішні положення організації MRIYA UA z.s. що до надання гуманітарної допомоги',
        acceptRulesInfo: `
В першу чергу допомога надається:
1. Особі, яка має громадянство України та постійно проживала на території даної держави до 25.02.2022.
2. Особі, яка заявляє, що вперше в'їхала на територію Чеської Республіки після 25.02.2022 р.
3. Особі, яка отримала травми та потребує невідкладної медичної допомоги.
4. Особі, яка має посвідчення про інвалідність, тяжке захворювання чи інші вади здоров’я.
5. Особі, яка доглядає за іншим членом сім'ї, який має посвідчення про інвалідність, тяжке захворювання чи інші вади
здоров’я.
6. Пенсіонерам
7. Матерям з неповнолітніми дітьми або, іншому законному представнику неповнолітніх (опікунам), насамперед одиноким
батькам.
8. Особі, яка походить з регіону України, в якому зараз ведуться активні бойові дії.
9. Особі, яка заявляє, що не володіє особистою нерухомістю призначеною для житла як на території Чеської Республіки, так
і на території іншої держави-члена ЄС.
10. Особі, яка заявляє, що не має родичів та знайомих на території Чеської Республіки, які б змогли надати тимчасове житло
та першу матеріальну допомогу.
11. Особі, яка заявляє, що не має фінансових можливостей для самостійного винаймання житла та предметів першої
необхідності.
Допомога буде надаватися іншим особам, які звертаються за допомогою, на вторинній основі, за умови наявності
наявних можливостей та гуманітарних коштів.
* Особа, яка звертається за допомогою, знайома та погоджується з тим, що MRIYA UA z.s. IČO: 143 39 242 є неприбутковою
організацією і вся допомога, яку надає організація, є добровільною.
* Особа, яка звертається за допомогою, погоджується з тим, що MRIYA UA z.s. IČO: 143 39 242 надає їй допомогу лише один
раз. Повторна допомога може надаватися лише особам, які перебувають у винятковій ситуації і лише за згодою
уповноважених осіб.
На жаль, ми не зможемо вам всім допомогти:
Наша робота полягає в наданні допомоги, необхідної для забезпечення основних життєвих потреб. На жаль, у нас
обмежені можливості, тому ми займаємося тільки певними напрямками допомоги людям, які опинилися у складній
життєвій ситуації. Ми не надаємо готівку, загальну інформацію чи послуги з догляду. Якщо ми не вирішуємо вашу
проблему або не працюємо поблизу вашого місця проживання, спробуйте пошукати послугу за допомогою Реєстру
надавачів соціальних послуг.
Я згоден з внутрішніми положеннями організації MRIYA UA z.s./Souhlasím s interními směrnicemi spolku MRIYA UA
z.s.`,
        GDPRInfoTitle: '',
        GDPRInfo: `
Погоджуюсь, що я ознайомився/лась з інформацією щодо обробки персональних даних відповідно до Регламенту (ЄС) 
№2016/679 Європейського Парламенту та Ради  Європи від 27.04.2016 ("GDPR"). Погоджуюсь на території Чеської Республіки 
та на цій основі, щоб організація MRIYA UA z.s., ідентифікаційний номер: 143 39 242, або нею призначений обробник 
обробляв і захищав, упродовж терміну надання гуманітарної допомоги як мені особисто, так і неповнолітнім дітям, якими 
я опікуюсь,  відповідно до законодавства Чеської Республіки, мої особисті дані у такому обсязі: ім’я та прізвище, дата 
народження, адреса постійного та тимчасового проживання, номер та тип документа, що посвідчує особу, громадянство, а 
також інші мною надані дані для виконання GDPR та виконання зобов’язань організації MRIYA UA z.s. Враховуючи GDPR, я 
також  погоджуюся, щоб MRIYA UA z.s., ідентифікаційний номер: 143 39 242 надавала у зв'язку з отриманням гуманітарної 
допомоги мої персональні дані іншій особі, яка будь-якою мірою братиме участь у наданні послуг гуманітарної підтримки 
для мене. Відвідувач CPP MRIYA (Центру допомоги та підтримки MRIYA) або інший отримувач послуг від ТОВ 
MRIYA UA z.s. погоджується на використання фотографій, аудіо/відеозаписів за його участю ТОВ MRIYA UA z.s. з метою 
презентації діяльності CPP та товариства задля документування (доведення) діяльності ТОВ MRIYA UA z.s. в розумінні 
його статутних документів.

*Підписуючи дану Анкету, я даю згоду на використання моїх персональних даних.`,
        notAccompanied: 'Без супроводу дорослих',
        notAdult: 'Дітей зареєструйте в розділі нижче. Дітей без супроводу дорослих, що хочуть зареєструватись, чекаємо у нашому центрі',
        noValue: "-----------------",
    }
}

const refugee = {
    cs: {
        familyId: 'Family ID',
        document: 'Document',
        refugeeRegistration: 'Refugee registration',
        helpNeeded: 'Help is needed',
        humanitarianHelp: 'Humanitarian help',
        needHumanitarianHelp: 'Need humanitarian help',
        gotHumanitarianHelp: 'Got humanitarian help',
        askedHumanitarianHelp: 'Asked for humanitarian help',
        accommodation: 'Accommodation',
        adults: 'Adults',
        adultsCount: 'Adults count',
        personalData: 'Personal data',
        name: 'Name',
        nameInLatin: 'Name (latin)',
        secondName: 'Second name',
        secondNameInLatin: 'Second name (latin)',
        sex: 'Sex',
        refugeeSex: 'Refugee sex',
        profession: 'Profession',
        professionInUkrainian: 'Profession (ukrainian)',
        phone: 'Phone',
        phoneNumber: 'Phone number',
        email: 'E-mail',
        emailAddress: 'E-mail address',
        languages: 'Languages',
        languagesKnows: 'What languages does he speak',
        id: 'ID',
        type: 'Type',
        idType: 'ID type',
        number: 'Number',
        idNumber: 'ID number',
        addressInUkraine: 'Address in Ukraine',
        city: 'City',
        cityOfLiveInUkrainian: 'City of live (ukrainian)',
        street: 'Street',
        streetOfLiveInUkrainian: 'Street of live (ukrainian)',
        houseNumber: 'House number',
        accompany: 'Accompany',
        children: 'Children',
        childrenCount: "Children count",
        childrenAge: "Children age",
        pets: 'Pets',
        notes: 'Additional notes',
        registered: 'Registered',
        registrationDate: 'Registration date',
        borderCrossed: 'Border crossed',
        borderCrossedDate: 'Date of border crossing',
        additionalHelp: 'Additional help',
        refugeeSearch: 'Refugee search',
        refugeeNotFound: 'No refugee found with provided data',
        refugee: 'Refugee info',
        needsHelpAgain: 'Needs help again',
        humanitarianHelpNotAsked: 'Did not ask for humanitarian help',
        humanitarianHelpNotProvided: 'Humanitarian help wasn\'t provided',
        accommodationNotProvided: 'Accommodation wasn\'t provided'
    },
    uk: {
        familyId: 'ID родини',
        document: 'Документ',
        refugeeRegistration: 'Реєстрація біженця',
        helpNeeded: 'Необхідна допомога',
        humanitarianHelp: 'Гуманітарна допомога',
        needHumanitarianHelp: 'Потребує гуманітарну допомогу',
        gotHumanitarianHelp: 'Отримав гуманітарну допомогу',
        askedHumanitarianHelp: 'Приходив за гуманітарною допомогою',
        accommodation: 'Житло',
        adults: 'Дорослих',
        adultsCount: 'Кількість дорослих',
        personalData: 'Особисті дані',
        name: 'Імʼя',
        nameInLatin: 'Ім’я (латинкою)',
        secondName: 'Прізвище',
        secondNameInLatin: 'Прізвище (латинкою)',
        sex: 'Стать',
        refugeeSex: 'Cтать біженця',
        profession: 'Професія',
        professionInUkrainian: 'Професія (українською)',
        phone: 'Телефон',
        phoneNumber: 'Номер телефону',
        email: 'E-mail',
        emailAddress: 'Адреса електроної пошти',
        languages: 'Мови',
        languagesKnows: 'Якими мовами володіє',
        id: 'Посвідчувальний документ',
        type: 'Тип',
        idType: 'Тип документу ідентифікації',
        number: 'Номер',
        idNumber: 'Номер документу ідентифікації',
        addressInUkraine: 'Адреса в Україні',
        city: 'Місто',
        cityOfLiveInUkrainian: 'Місто проживання (українською)',
        street: 'Вулиця',
        streetOfLiveInUkrainian: 'Вулиця проживання (українською)',
        houseNumber: 'Номер будинку проживання',
        accompany: 'Супроводжує',
        children: 'Дітей',
        childrenCount: 'Кількість дітей',
        childrenAge: 'Вік дітей',
        pets: 'Домашні тварини',
        notes: 'Додаткова інформація',
        registered: 'Зареєстрований',
        registrationDate: 'Дата реєстрації',
        borderCrossed: 'Перетнув кордон',
        borderCrossedDate: 'Дата перетину кордону',
        additionalHelp: 'Додаткова допомога',
        refugeeSearch: 'Пошук біженця',
        refugeeNotFound: 'Біженця з такими даними не знайдено',
        refugee: 'Інформація про біженця',
        needsHelpAgain: 'Знов потребує допомоги',
        humanitarianHelpNotAsked: 'Не потребував гуманітарної допомоги',
        humanitarianHelpNotProvided: 'Гуманітарна допомога не надавалась',
        accommodationNotProvided: 'Житло не надавалось'
    }
}

const sex = {
    cs: {
        MALE: 'Muz',
        FEMALE: 'Zena'
    },
    uk: {
        MALE: 'Чоловіча',
        FEMALE: 'Жіноча'
    }
}
const educationalInstitution = {
    cs: {
        KINDERGARTEN: "KINDERGARTEN",
        SCHOOL: "SCHOOL",
        GYMNASIUM: "GYMNASIUM",
        COLLEGE: "COLLEGE",
        UNIVERSITY: "UNIVERSITY",
        OTHER: "OTHER",
    },
    uk: {
        KINDERGARTEN: "Садочок",
        SCHOOL: "Школа",
        GYMNASIUM: "Гімназія",
        COLLEGE: "Коледж",
        UNIVERSITY: "Університет",
        OTHER: "Інше",
    }
}
const employmentType = {
    cs: {
        HPP: 'HPP',
        HPP_HALF: 'HPP 1/2',
        DPP: 'DPP',
        DPC: 'DPC',
    },
    uk: {
        HPP: 'HPP – Трудовий договір на повну ставку (40 годин/тиждень)',
        HPP_HALF: 'HPP 1/2 – Трудовий договір на пів-ставки (20 годин/тиждень)',
        DPP: 'DPP – Трудовий договір на виконання певного виду робіт (почасова оплата)',
        DPC: 'DPČ – Трудовий договір на неповний робочий тиждень (макс. 20 годин/тиждень)',
    }
}

const idType = {
    cs: {
        INTERNAL_PASS: 'INTERNAL_PASS',
        INTERNATIONAL_PASS: 'INTERNATIONAL_PASS',
        CERTIFICATE_OF_LOSS: 'CERTIFICATE_OF_LOSS',
    },
    uk: {
        INTERNAL_PASS: 'Паспорт',
        INTERNATIONAL_PASS: 'Закордоний паспорт',
        CERTIFICATE_OF_LOSS: 'Довідка про втрату',
    }
}

const disabilityGroup = {
    cs: {
        GROUP_1_A: "1A",
        GROUP_1_B: "1B",
        GROUP_2: "2",
        GROUP_3: "3",
    }, uk: {
        GROUP_1_A: "1A група",
        GROUP_1_B: "1Б група",
        GROUP_2: "2 група",
        GROUP_3: "3 група",
    }
}

const language = {
    cs: {
        ANOTHER: 'ANOTHER',
        EN: 'EN',
        CZ: 'CZ',
        RU: 'RU',
        UA: 'UA',
    },
    uk: {
        ANOTHER: 'Інші',
        EN: 'Англійська',
        CZ: 'Чеська',
        RU: 'Російська',
        UA: 'Українська',
    }
}

const educationLevel = {
    cs: {
        MIDDLE_BASE: 'MIDDLE_BASE',
        MIDDLE_SPECIALIZED: 'MIDDLE_SPECIALIZED',
        MIDDLE_FULL: 'MIDDLE_FULL',
        BACHELOR: 'BACHELOR',
        MASTER: 'MASTER',
        DOCTORATE: 'DOCTORATE',
        OTHER: 'OTHER',
    },
    uk: {
        MIDDLE_BASE: 'Базова середня',
        MIDDLE_SPECIALIZED: 'Середня спеціалізована',
        MIDDLE_FULL: 'Повна середня',
        BACHELOR: 'Бакалавр',
        MASTER: 'Магістр',
        DOCTORATE: 'Доктор',
        OTHER: 'Інша',
    },
}

const button = {
    cs: {
        add: 'Pridat',
        save: 'Ulozit',
        cancel: 'Zrusit',
        new: 'Novy',
        notify: 'Oznamit',
        search: 'Hledat',
        saveChanges: 'Save changes',
        edit: 'Editovat',
        register: 'Registrovat'
    },
    uk: {
        add: 'Додати',
        save: 'Зберегти',
        cancel: 'Відміна',
        new: 'Новий',
        notify: 'Повідомити',
        search: 'Шукати',
        saveChanges: 'Зберегти зміни',
        edit: 'Редагувати',
        register: 'Зареєструватись'
    }
}

const messages = {
    uk: {
        info: {
            error: 'Помилка',
            unknown: 'Невідомий',
            save: 'Зберегти',
            edit: 'Редагувати',
            no_data_text: 'Немає даних',
            search: 'Пошук',
            field_required: 'Обовʼязкове поле',
            field_natural_number: 'Має бути ціле число >= 0',
            field_integer: 'Має бути ціле число більше за 0',
            field_number: 'Має бути число',
            field_already_exist: 'Таке значення вже існує',
            field_email: 'Має бути email',
            showAll: 'Показати все',
            non_empty_list: 'Хоча б одне значення має бути вибрано',
            only_latin: 'Дозволена тільки латинка',
            only_ukrainian: 'Дозволена тільки українська абетка',
            only_latin_and_numbers: 'Дозволена тільки латинка і цифри',
            only_ukrainian_and_numbers: 'Дозволена тільки українська абетка і цифри',
            email: "Неправильний e-mail",
            only_czech_and_numbers: 'Дозволена тільки чеська абетка і цифри',
        },
        refugee: refugee.uk,
        button: button.uk,
        sex: sex.uk,
        idType: idType.uk,
        language: language.uk,
        registration: registration.uk,
        educationLevel: educationLevel.uk,
        disabilityGroup: disabilityGroup.uk,
        employmentType: employmentType.uk,
        educationalInstitution: educationalInstitution.uk,
        approver: approver.uk,
        department: department.uk,
    },
    cs: {
        info: {
            error: 'Error',
            unknown: 'Unknown',
            save: 'Save',
            edit: 'Edit',
            no_data_text: 'No data available',
            search: 'Search',
            field_required: 'Field is required',
            field_natural_number: 'Should be natural number >= 0',
            field_integer: 'Should be natural number > 0',
            field_number: 'Number is required',
            field_already_exist: 'Such value already exist',
            field_email: 'Email is required',
            showAll: 'Show all',
            non_empty_list: 'At least one value should be selected',
            only_latin: 'Only latin letters are allowed',
            only_ukrainian: 'Only ukrainian letters are allowed',
            only_latin_and_numbers: 'Only numbers and latin letters are allowed',
            only_ukrainian_and_numbers: 'Only numbers and ukrainian letters are allowed',
            email: "Invalid e-mail",
            only_czech_and_numbers: 'Only numbers and czech letters are allowed',
        },
        refugee: refugee.cs,
        button: button.cs,
        sex: sex.cs,
        idType: idType.cs,
        language: language.cs,
        registration: registration.cs,
        educationLevel: educationLevel.cs,
        disabilityGroup: disabilityGroup.cs,
        employmentType: employmentType.cs,
        educationalInstitution: educationalInstitution.cs,
        approver: approver.cs,
        department: department.cs,
    }
}

const i18n = new VueI18n({
    locale: 'uk',
    fallbackLocale: 'en',
    messages,
    silentTranslationWarn: true
})

export default i18n
