import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import '@aws-amplify/ui-vue'
import Amplify from 'aws-amplify'
import config from '../config/config'

Vue.config.productionTip = false

Amplify.configure(config)

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
