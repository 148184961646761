import Vue from 'vue'

export class Notify {

    private static instance: Notify = new Notify()
    private static EVENT = 'notify'

    private eventBus: Vue = new Vue()

    static info(message: string) {
        Notify.sendMessage('info', message)
    }

    static success(message: string) {
        Notify.sendMessage('success', message)
    }

    static error(message: string) {
        Notify.sendMessage('error', message)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static subscribe(callback: (color: string, message: string) => any) {
        Notify.instance.eventBus.$on(Notify.EVENT, (payload: { color: string, message: string }) => {
            callback(payload.color, payload.message)
        })
    }

    private static sendMessage(color: string, message: string) {
        Notify.instance.eventBus.$emit(Notify.EVENT, {color, message})
    }
}
