
import Vue from "vue";
import {Component} from "vue-property-decorator";
import {Getter} from "vuex-class";

@Component({
  i18n: {
    messages: {
      cs: {
        youth: "R4",
        social: "R3",
        reception: "Reception",
        medic: "R1",
        education: "R2",
        admin: "Approver",
        warehouse: "Warehouse",
        approverSection: "Approver",
      },
      uk: {
        youth: "R4",
        social: "R3",
        reception: "Рецепція",
        medic: "R1",
        education: "R2",
        admin: "Адмін",
        warehouse: "Склад",
        approverSection: "Контроль",
      },
    }
  }
})
export default class LeftMenu extends Vue {

  @Getter
  isYouth!: boolean
  @Getter
  isWarehouse!: boolean
  @Getter
  isSocial!: boolean
  @Getter
  isReception!: boolean
  @Getter
  isMedic!: boolean
  @Getter
  isEducation!: boolean
  @Getter
  isApprover!: boolean
  @Getter
  isAdmin!: boolean
}
