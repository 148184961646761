
import HeaderView from "./components/HeaderView.vue";
import {Component, Vue} from "vue-property-decorator";
import {Notify} from "@/utils/helper";
import LeftMenu from "@/views/LeftMenu.vue";

@Component({
  components: {LeftMenu, HeaderView}
})
export default class PublicPage extends Vue {

  snackbar = false
  message = ''
  color = ''

  mounted() {
    Notify.subscribe((color, message) => {
      this.color = color
      this.message = message
      this.snackbar = true
    })
  }
}
