import Vue from 'vue'
import VueRouter, {Route, RouteConfig} from 'vue-router'
import {checkCurrentUser} from "@/services/authentication";
import store from '../store'
import PublicPage from "@/views/Public.vue";
import ForwardPage from "@/views/ForwardPage.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/login', name: 'login', component: () => import('../views/login/Login.vue')
    },
    {
        path: '/error/:errorCode', name: 'error', props: true, component: () => import('../views/Error.vue')
    },
    {
        path: '/',
        component: PublicPage,
        children: [
            {
                path: 'register', name: 'register', component: () => import('../views/Register.vue')
            },
            {
                path: 'admin',
                component: ForwardPage,
                children: [
                    {
                        path: 'home', name: 'admin-home', redirect: '/'
                    },
                    {
                        path: 'familyMember/adult/:familyId',
                        name: 'admin-family-member-adult-add',
                        props: true,
                        component: () => import('../views/groups/admin/familyMember/AdultEdit.vue')
                    },
                    {
                        path: 'familyMember/adult/:familyId/:personId',
                        name: 'admin-family-member-adult-edit',
                        props: true,
                        component: () => import('../views/groups/admin/familyMember/AdultEdit.vue')
                    },
                    {
                        path: 'familyMember/child/:familyId',
                        name: 'admin-family-member-child-add',
                        props: true,
                        component: () => import('../views/groups/admin/familyMember/ChildEdit.vue')
                    },
                    {
                        path: 'familyMember/child/:familyId/:personId',
                        name: 'admin-family-member-child-edit',
                        props: true,
                        component: () => import('../views/groups/admin/familyMember/ChildEdit.vue')
                    }
                ]
            },
            {
                path: 'reception',
                component: ForwardPage,
                children: [
                    {
                        path: 'home', name: 'reception-home', redirect: { name: 'reception_search' }
                    },
                    {
                        path: 'family_person/:id?',
                        name: 'reception_search',
                        component: () => import('../views/groups/reception/Search.vue')
                    }
                ]
            },
            {
                path: 'approver',
                component: ForwardPage,
                children: [
                    {
                        path: 'home', name: 'approver-home', redirect: { name: 'approver_search' }
                    },
                    {
                        path: 'registration/:idNumber?',
                        name: 'approver_search',
                        component: () => import('../views/groups/approver/Search.vue')
                    },
                    {
                        path: 'approve_create/:registrationId?',
                        name: 'approver_create',
                        props: true,
                        component: () => import('../views/groups/approver/Create.vue')
                    }
                ]
            },
            {
                path: 'medic',
                component: ForwardPage,
                children: [
                    {
                        path: 'home', name: 'medic-home', redirect: { name: 'medic-person-search' }
                    },
                    {
                        path: 'family/:familyId?',
                        name: 'medic-person-search',
                        component: () => import('../views/groups/medic/Search.vue')
                    },
                    {
                        path: 'person/:familyId/:personId',
                        name: 'medic-person-update',
                        props: true,
                        component: () => import('../views/groups/medic/Update.vue'),
                    }
                ]
            },
            {
                path: 'education',
                component: ForwardPage,
                children: [
                    {
                        path: 'home', name: 'education-home', redirect: { name: 'education-person-search' }
                    },
                    {
                        path: 'family/:familyId?',
                        name: 'education-person-search',
                        component: () => import('../views/groups/education/Search.vue')
                    }, {
                        path: 'person/:familyId/:personId',
                        name: 'education-person-update',
                        props: true,
                        component: () => import('../views/groups/education/Update.vue'),
                    }
                ]
            },
            {
                path: 'social',
                component: ForwardPage,
                children: [
                    {
                        path: 'home', name: 'social-home', redirect: { name: 'social-person-search' }
                    },
                    {
                        path: 'family/:familyId?',
                        name: 'social-person-search',
                        component: () => import('../views/groups/social/Search.vue')
                    },
                    {
                        path: 'person/:familyId/:personId',
                        name: 'social-person-update',
                        props: true,
                        component: () => import('../views/groups/social/Update.vue'),
                    }
                ]
            },
            {
                path: 'youth',
                component: ForwardPage,
                children: [
                    {
                        path: 'home', name: 'youth-home', redirect: { name: 'youth-person-search' }
                    }, {
                        path: 'family/:familyId?',
                        name: 'youth-person-search',
                        component: () => import('../views/groups/youth/Search.vue')
                    }, {
                        path: 'person/:familyId/:personId',
                        name: 'youth-person-update',
                        props: true,
                        component: () => import('../views/groups/youth/Update.vue'),
                    }
                ]
            },
            {
                path: 'warehouse',
                component: ForwardPage,
                children: [
                    {
                        path: 'home', name: 'warehouse-home', redirect: {name: 'warehouse-person-search'}
                    }, {
                        path: 'family/:familyId?',
                        name: 'warehouse-person-search',
                        component: () => import('../views/groups/warehouse/Search.vue')
                    }, {
                        path: 'person/:familyId/:personId',
                        name: 'warehouse-person-update',
                        props: true,
                        component: () => import('../views/groups/warehouse/Update.vue'),
                    }
                ]
            }
        ]
    }
]

const router = new VueRouter({
    routes,
})

const publicToAccess = ['login', 'error', 'register']

router.beforeEach(async (to, from, next) => {
    if (to.name && publicToAccess.includes(to.name)) {
        next()
        return
    }

    try {
        const userSession = await checkCurrentUser()
        await store.commit('setUserSession', userSession)
        await executeActions(to)
        next()
    } catch (e) {
        next({ name: 'login' })
    }
})

async function executeActions(to: Route) {
    return Promise.all(to.matched
        .filter(_ => _.meta && _.meta.action)
        .flatMap(_ => _.meta.action)
        .map(_ => store.dispatch(_, to.params)))
}

export default router
