import {Auth} from "aws-amplify";

export function checkCurrentUser() {
    return Auth.currentSession()
}

export async function getUserGroups(): Promise<string[]> {
    const session = await Auth.currentSession()
    return session.getAccessToken().decodePayload()['cognito:groups'] || []
}
